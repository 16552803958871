import React, { FC, useState, useEffect } from 'react';
import axios from 'axios'
import './App.css';
import { Person } from './components/Person'
import { IPerson } from './interfaces';


const App: FC = () => {

  const [persons, setPersons] = useState<IPerson[]>([]);
  
  useEffect(() => {
    const apiUrl = 'https://reqres.in/api/users';
    axios.get(apiUrl).then((resp) => {
      setPersons(resp.data.data);
    });
  }, [setPersons]);

  return (
    <div className="App">
      <div>Deploy test</div>
      {
        persons.length && persons.map((person: IPerson) =>
          <Person {...person} />
        )
      }
    </div>
  );
}

export default App;
